a {
    cursor: pointer;
}

a[disabled] {
    pointer-events: none;
}

.login {
    margin-top: 20%;
}

.alert-medium {
    padding: 10px;
}

.alert-medium.alert-dismissable {
    padding-right: 35px;
}

.nav-tabs {
}

.phone {
    color: #ffffff;
    font-size: 150px;
    padding: 40px;
    border-radius: 115px;
}

.phone.green {
    background: green;
}

.phone.red {
    background: red;
}

.phone.yellow {
    background: yellow;
}

.label-left.form-horizontal .control-label {
    text-align: left;
}

.text-bold {
    font-weight: bold;
}

.text-underline {
    text-decoration: underline;
}

.text-strike {
    text-decoration: line-through;
}

.tabs-active-bold li.active a {
    font-weight: bold;
}

.matching-item {
    padding: 3px;
    border: 1px solid #ccc;
    margin-bottom: 5px;
}

.matching-item > span {
    display: block;
    border-radius: 3px;
    border-bottom: 1px dashed #ccc;
    font-size: 12px;
}

.matching-item > span:last-of-type {
    border-bottom: 0;
}

.matching-item > .provider-name {
    font-weight: bold;
}

.ta-toolbar > .btn-group {
    margin-bottom: 5px;
}

.modal-backdrop {
    position: fixed;
    bottom: 0;
}

form.input-mb-10 input,
form.input-mb-10 select,
form.input-mb-10 .ui-select-container {
    margin-bottom: 10px;
}

.sr-only {
    position: relative;
    width: auto;
    height: auto;
    margin-bottom: 5px;
}
.mb-0 {
    margin-bottom: 0;
}

.mb-5 {
    margin-bottom: 5px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-60 {
    margin-bottom: 60px;
}

.mb-80 {
    margin-bottom: 80px;
}

.mt-5 {
    margin-top: 5px !important;
}

.mt-10 {
    margin-top: 10px !important;
}
.mt-20 {
    margin-top: 20px;
}

.mb-100 {
    margin-bottom: 100px;
}

.ml-10
{
    margin-left: 10px !important;
}
.mr-5 {
    margin-right: 5px !important;
}
.mr-10 {
    margin-right: 5px !important;
}
.mr-20 {
    margin-right: 20px !important;
}
.min150px {
    min-width: 150px;
}
.p-0 {
    padding: 0px !important;
}
.pt-30 {
    padding-top: 30px;
}
@keyframes shake {
    0% { transform:         translate(2px, 1px) rotate(0deg); }
    10% { transform:     translate(-1px, -2px) rotate(-20deg); }
    20% { transform:     translate(-3px, 0px) rotate(30deg); }
    30% { transform:     translate(0px, 2px) rotate(0deg); }
    40% { transform:     translate(1px, -1px) rotate(10deg); }
    50% { transform:     translate(-1px, 2px) rotate(-10deg); }
    60% { transform:     translate(-3px, 1px) rotate(0deg); }
    70% { transform:     translate(2px, 1px) rotate(-20deg); }
    80% { transform:     translate(-1px, -1px) rotate(40deg); }
    90% { transform:     translate(2px, 2px) rotate(0deg); }
    100% { transform:     translate(1px, -2px) rotate(-10deg); }
}

@-webkit-keyframes shake {
    0% { -webkit-transform:         translate(2px, 1px) rotate(0deg); }
    10% { -webkit-transform:     translate(-1px, -2px) rotate(-20deg); }
    20% { -webkit-transform:     translate(-3px, 0px) rotate(30deg); }
    30% { -webkit-transform:     translate(0px, 2px) rotate(0deg); }
    40% { -webkit-transform:     translate(1px, -1px) rotate(10deg); }
    50% { -webkit-transform:     translate(-1px, 2px) rotate(-10deg); }
    60% { -webkit-transform:     translate(-3px, 1px) rotate(0deg); }
    70% { -webkit-transform:     translate(2px, 1px) rotate(-20deg); }
    80% { -webkit-transform:     translate(-1px, -1px) rotate(40deg); }
    90% { -webkit-transform:     translate(2px, 2px) rotate(0deg); }
    100% { -webkit-transform:     translate(1px, -2px) rotate(-10deg); }
}

.shake {
    animation-name: shake;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    transform-origin: 50% 100%;
    -webkit-animation-name: shake;
    -webkit-animation-duration: 1.5s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -webkit-transform-origin: 50% 100%;
}

body.nature-bg::after {
    content: '';
    background-image: url("../design/nature-bg.jpg");
    background-size: 100%;
    background-position: center;
    position: fixed;
    opacity: 0.3;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
}

.modal-xlg > .modal-dialog {
    width: 1024px;
}

.modal-xxlg > .modal-dialog {
    width: 1180px;
}

.modal-max > .modal-dialog {
    width: 98%;
}

.auto-width
{
    width: 154px !important;
}

.modal-lg {
  width: 1024px !important;
}

.rowBorder
{
    border-left: 1px solid #ddd;
}

.modal-padding
{
    padding: 25px !important;
}



.stats {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  width: 282px;
  height: 90px;
  margin: 10px;
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  overflow: hidden;
  border-radius: 8px;
}
.stats a {
  position: relative;
  display: block;
  padding: 12px 25px 25px 25px;
  color: #fff;
  text-decoration: none;
  z-index: 2;
}
.stats a span {
  display: block;
  font-size: 46px;
  font-weight: 700;
  line-height: 46px;
}
.stats:after {
  position: absolute;
  font-family: FontAwesome;
  color: #000000;
  z-index: 1;
  transition: all .5s;
  line-height: normal;
}
.stats.Toplam {
  background-color: #b8aa0e;
}
.stats.Toplam:after {
  content: "\f08d";
  font-size: 200px;
  color: #a5980d;
  top: 45px;
  right: 60px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.stats.Toplam:hover:after {
  top: 8px;
}
.stats.Bekleyen {
  background-color: #0377c0;
}
.stats.Bekleyen:after {
  content: "\f075";
  font-size: 180px;
  color: #036bac;
  top: 30px;
  right: 35px;
}
.stats.Bekleyen:hover:after {
  top: -5px;
}
.stats.Onaylanmis {
  background-color: #279824;
}
.stats.Onaylanmis:after {
  content: "\f15c";
  font-size: 160px;
  color: #238820;
  top: 62px;
  right: 39px;
}
.stats.Onaylanmis:hover:after {
  top: 24px;
}
.stats.Reddedilmis {
  background-color: #fc1c3e;
}
.stats.Reddedilmis:after {
  content: "\f007";
  font-size: 200px;
  color: #ec0326;
  top: 45px;
  right: 35px;
}
.stats.Reddedilmis:hover:after {
  top: 7px;
}
.stats:hover:after {
  transition: all .5s;
}

div.tooltip {
    max-width: 350px !important;
}

div.tooltip.tooltip-wide {
    width: 400px;
}

div.tooltip.tooltip-wide > .tooltip-inner {
    max-width: 400px;
}


.nav-tabs.nav-justified>.active>a
{
    border-bottom-color: #dddddd;
    background-color: #E4F1FE;
    color: #222222;
}

.showIfLast {
    display: none;
}

/* Only show it if it is also the last row of the table. */
.showIfLast:last-child {
    display: table-row;
}

.showIfLast td {
    text-align: center;
}

.showIfLast td:after {
    content: "Kayıt Bulunamadı.";
    white-space: pre;
    color: #888;
}

th.tablesort-sortable {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    cursor: pointer;
}

table .tablesort-sortable:after{
    content:"";
    float:right;
    margin-top:7px;
    visibility:hidden;
    border-left:4px solid transparent;
    border-right:4px solid transparent;

    border-top:none;
    border-bottom:4px solid #000;
}

table .tablesort-desc:after{
    border-top:4px solid #000;
    border-bottom:none;
}

table .tablesort-asc,table .tablesort-desc{
    background-color:rgba(141, 192, 219, 0.25);
}

table .tablesort-sortable:hover:after, table .tablesort-asc:after, table .tablesort-desc:after {
    visibility:visible;
}

.stats-font {
    font-size: 24px;
    font-weight: bold;
}

.mg-lft-10
{
    margin-left: -10px !important;
}

.mg-lft-0 { margin-left: 0px !important;}
.mg-right-0 { margin-right: 0px !important;}

input[type="checkbox"] {
    display: none !important;
}

.text-count
{
    font-size: 18px;
    margin-top: 5px;
    display: block;
}

.cg-busy{
    position:absolute;
    top:0px;
    left:0px;
    right:0px;
    bottom:0px;
    z-index:1001;
}

.cg-busy-animation.ng-hide-add,
.cg-busy-animation.ng-hide-remove {
    -webkit-transition:all .3s ease;
    -moz-transition:all .3s ease;
    -o-transition:all .3s ease;
    transition:all .3s ease;
    display:block !important;
}
.cg-busy-animation.ng-hide-remove {
    opacity:0;
    -webkit-transform:translate(0px,-40px);
    -moz-transform:translate(0px,-40px);
    -ms-transform:translate(0px,-40px);
    -o-transform:translate(0px,-40px);
    transform:translate(0px,-40px);
}
.cg-busy-animation.ng-hide-remove.ng-hide-remove-active {
    opacity:1;
    -webkit-transform:translate(0px,0px);
    -moz-transform:translate(0px,0px);
    -ms-transform:translate(0px,0px);
    -o-transform:translate(0px,0px);
    transform:translate(0px,0px);
}
.cg-busy-animation.ng-hide-add {
    opacity:1;
    -webkit-transform:translate(0px,0px);
    -moz-transform:translate(0px,0px);
    -ms-transform:translate(0px,0px);
    -o-transform:translate(0px,0px);
    transform:translate(0px,0px);
}
.cg-busy-animation.ng-hide-add.ng-hide-add-active {
    opacity:0;
    -webkit-transform:translate(0px,-40px);
    -moz-transform:translate(0px,-40px);
    -ms-transform:translate(0px,-40px);
    -o-transform:translate(0px,-40px);
    transform:translate(0px,-40px);
}

.cg-busy-backdrop {
    background-color:white;
    opacity:.7;
}

.cg-busy-backdrop-animation.ng-hide-add,
.cg-busy-backdrop-animation.ng-hide-remove {
    -webkit-transition:opacity .3s ease;
    -moz-transition:opacity .3s ease;
    -o-transition:opacity .3s ease;
    transition:opacity .3s ease;
    display:block !important;
}

.cg-busy-backdrop-animation.ng-hide {
    opacity:0;
}

/* All styles below are for the default template. */

.cg-busy-default-wrapper {
    text-align:center;
}

.cg-busy-default-sign{
    display: inline-block;
    position:relative;
    z-index:1002;
    padding-bottom: 6px;
    color:#333333;
    text-shadow:0 1px 1px rgba(255, 255, 255, 0.75);
    background-color:#e9eeee;
    border:1px solid #dddddd;
    border-top-width:0;
    -webkit-border-radius:7px;
    -moz-border-radius:7px;
    border-radius:7px;
    border-top-left-radius:0;
    border-top-right-radius:0;
    -webkit-box-shadow:inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
    -moz-box-shadow:inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
    box-shadow:inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
}
.cg-busy-default-text{
    margin:13px 12px 6px 49px;
    font-size:16px;
    color:#555;
    text-align: left;
    max-width: 400px;
}
.cg-busy-default-spinner{
    position:absolute;
    width:25px;
    height:25px;
    display:inline-block;
    top:12px;
    left:14px;
}
.cg-busy-default-spinner div{
    width:12%;
    height:26%;
    background:#000;
    position:absolute;
    left:44.5%;
    top:37%;
    opacity:0;
    -webkit-animation:cg-busy-spinner-anim 1s linear infinite;
    -moz-animation:cg-busy-spinner-anim 1s linear infinite;
    -ms-animation:cg-busy-spinner-anim 1s linear infinite;
    -o-animation:cg-busy-spinner-anim 1s linear infinite;
    animation:cg-busy-spinner-anim 1s linear infinite;
    -webkit-border-radius:50px;
    -moz-border-radius:50px;
    border-radius:50px;
    -webkit-box-shadow:0 0 3px rgba(0,0,0,0.2);
    -moz-box-shadow:0 0 3px rgba(0,0,0,0.2);
    box-shadow:0 0 3px rgba(0,0,0,0.2);
}
.cg-busy-default-spinner div.bar1{
    -webkit-transform:rotate(0deg) translate(0, -142%);
    -moz-transform:rotate(0deg) translate(0, -142%);
    -ms-transform:rotate(0deg) translate(0, -142%);
    -o-transform:rotate(0deg) translate(0, -142%);
    transform:rotate(0deg) translate(0, -142%);
    -webkit-animation-delay:0s;
    -moz-animation-delay:0s;
    -ms-animation-delay:0s;
    -o-animation-delay:0s;
    animation-delay:0s;
}
.cg-busy-default-spinner div.bar2{
    -webkit-transform:rotate(30deg) translate(0, -142%);
    -moz-transform:rotate(30deg) translate(0, -142%);
    -ms-transform:rotate(30deg) translate(0, -142%);
    -o-transform:rotate(30deg) translate(0, -142%);
    transform:rotate(30deg) translate(0, -142%);
    -webkit-animation-delay:-0.9167s;
    -moz-animation-delay:-0.9167s;
    -ms-animation-delay:-0.9167s;
    -o-animation-delay:-0.9167s;
    animation-delay:-0.9167s;
}
.cg-busy-default-spinner div.bar3{
    -webkit-transform:rotate(60deg) translate(0, -142%);
    -moz-transform:rotate(60deg) translate(0, -142%);
    -ms-transform:rotate(60deg) translate(0, -142%);
    -o-transform:rotate(60deg) translate(0, -142%);
    transform:rotate(60deg) translate(0, -142%);
    -webkit-animation-delay:-0.833s;
    -moz-animation-delay:-0.833s;
    -ms-animation-delay:-0.833s;
    -o-animation-delay:-0.833s;
    animation-delay:-0.833s;
}
.cg-busy-default-spinner div.bar4{
    -webkit-transform:rotate(90deg) translate(0, -142%);
    -moz-transform:rotate(90deg) translate(0, -142%);
    -ms-transform:rotate(90deg) translate(0, -142%);
    -o-transform:rotate(90deg) translate(0, -142%);
    transform:rotate(90deg) translate(0, -142%);
    -webkit-animation-delay:-0.75s;
    -moz-animation-delay:-0.75s;
    -ms-animation-delay:-0.75s;
    -o-animation-delay:-0.75s;
    animation-delay:-0.75s;
}
.cg-busy-default-spinner div.bar5{
    -webkit-transform:rotate(120deg) translate(0, -142%);
    -moz-transform:rotate(120deg) translate(0, -142%);
    -ms-transform:rotate(120deg) translate(0, -142%);
    -o-transform:rotate(120deg) translate(0, -142%);
    transform:rotate(120deg) translate(0, -142%);
    -webkit-animation-delay:-0.667s;
    -moz-animation-delay:-0.667s;
    -ms-animation-delay:-0.667s;
    -o-animation-delay:-0.667s;
    animation-delay:-0.667s;
}
.cg-busy-default-spinner div.bar6{
    -webkit-transform:rotate(150deg) translate(0, -142%);
    -moz-transform:rotate(150deg) translate(0, -142%);
    -ms-transform:rotate(150deg) translate(0, -142%);
    -o-transform:rotate(150deg) translate(0, -142%);
    transform:rotate(150deg) translate(0, -142%);
    -webkit-animation-delay:-0.5833s;
    -moz-animation-delay:-0.5833s;
    -ms-animation-delay:-0.5833s;
    -o-animation-delay:-0.5833s;
    animation-delay:-0.5833s;
}
.cg-busy-default-spinner div.bar7{
    -webkit-transform:rotate(180deg) translate(0, -142%);
    -moz-transform:rotate(180deg) translate(0, -142%);
    -ms-transform:rotate(180deg) translate(0, -142%);
    -o-transform:rotate(180deg) translate(0, -142%);
    transform:rotate(180deg) translate(0, -142%);
    -webkit-animation-delay:-0.5s;
    -moz-animation-delay:-0.5s;
    -ms-animation-delay:-0.5s;
    -o-animation-delay:-0.5s;
    animation-delay:-0.5s;
}
.cg-busy-default-spinner div.bar8{
    -webkit-transform:rotate(210deg) translate(0, -142%);
    -moz-transform:rotate(210deg) translate(0, -142%);
    -ms-transform:rotate(210deg) translate(0, -142%);
    -o-transform:rotate(210deg) translate(0, -142%);
    transform:rotate(210deg) translate(0, -142%);
    -webkit-animation-delay:-0.41667s;
    -moz-animation-delay:-0.41667s;
    -ms-animation-delay:-0.41667s;
    -o-animation-delay:-0.41667s;
    animation-delay:-0.41667s;
}
.cg-busy-default-spinner div.bar9{
    -webkit-transform:rotate(240deg) translate(0, -142%);
    -moz-transform:rotate(240deg) translate(0, -142%);
    -ms-transform:rotate(240deg) translate(0, -142%);
    -o-transform:rotate(240deg) translate(0, -142%);
    transform:rotate(240deg) translate(0, -142%);
    -webkit-animation-delay:-0.333s;
    -moz-animation-delay:-0.333s;
    -ms-animation-delay:-0.333s;
    -o-animation-delay:-0.333s;
    animation-delay:-0.333s;
}
.cg-busy-default-spinner div.bar10{
    -webkit-transform:rotate(270deg) translate(0, -142%);
    -moz-transform:rotate(270deg) translate(0, -142%);
    -ms-transform:rotate(270deg) translate(0, -142%);
    -o-transform:rotate(270deg) translate(0, -142%);
    transform:rotate(270deg) translate(0, -142%);
    -webkit-animation-delay:-0.25s;
    -moz-animation-delay:-0.25s;
    -ms-animation-delay:-0.25s;
    -o-animation-delay:-0.25s;
    animation-delay:-0.25s;
}
.cg-busy-default-spinner div.bar11{
    -webkit-transform:rotate(300deg) translate(0, -142%);
    -moz-transform:rotate(300deg) translate(0, -142%);
    -ms-transform:rotate(300deg) translate(0, -142%);
    -o-transform:rotate(300deg) translate(0, -142%);
    transform:rotate(300deg) translate(0, -142%);
    -webkit-animation-delay:-0.1667s;
    -moz-animation-delay:-0.1667s;
    -ms-animation-delay:-0.1667s;
    -o-animation-delay:-0.1667s;
    animation-delay:-0.1667s;
}
.cg-busy-default-spinner div.bar12{
    -webkit-transform:rotate(330deg) translate(0, -142%);
    -moz-transform:rotate(330deg) translate(0, -142%);
    -ms-transform:rotate(330deg) translate(0, -142%);
    -o-transform:rotate(330deg) translate(0, -142%);
    transform:rotate(330deg) translate(0, -142%);
    -webkit-animation-delay:-0.0833s;
    -moz-animation-delay:-0.0833s;
    -ms-animation-delay:-0.0833s;
    -o-animation-delay:-0.0833s;
    animation-delay:-0.0833s;
}

@-webkit-keyframes cg-busy-spinner-anim{
    from {opacity: 1;}
    to {opacity: 0.25;}
}
@-moz-keyframes cg-busy-spinner-anim{
    from {opacity: 1;}
    to {opacity: 0.25;}
}
@keyframes cg-busy-spinner-anim{
    from {opacity: 1;}
    to {opacity: 0.25;}
}

.notifications .notifications-container{position:fixed;top:0;left:0;min-height:60px;line-height:60px;width:100%;z-index:1000}.notifications .error,.notifications .notification,.notifications .success,.notifications .warning{position:relative;-webkit-animation:fadeInDown 1s ease .2s 1 both;-moz-animation:fadeInDown 1s ease .2s 1 both;animation:fadeInDown 1s ease .2s 1 both;-webkit-animation-backface-visibility:hidden;-moz-animation-backface-visibility:hidden;-ms-animation-backface-visibility:hidden;-o-animation-backface-visibility:hidden;animation-backface-visibility:hidden;text-align:center;font-size:18px;color:#fff}@-webkit-keyframes fadeInDown{0%{opacity:0;-webkit-transform:translateY(-20px)}100%{opacity:1;-webkit-transform:translateY(0)}}@-moz-keyframes fadeInDown{0%{opacity:0;-moz-transform:translateY(-20px)}100%{opacity:1;-moz-transform:translateY(0)}}@-o-keyframes fadeInDown{0%{opacity:0;-o-transform:translateY(-20px)}100%{opacity:1;-o-transform:translateY(0)}}@keyframes fadeInDown{0%{opacity:0;-webkit-transform:translateY(-20px);transform:translateY(-20px)}100%{opacity:1;-webkit-transform:translateY(0);transform:translateY(0)}}.notifications .message{padding:0 12px}.notifications .error{background-color:#F64747;border-bottom:1px solid #f31515}.notifications .success{background-color:#03C9A9;border-bottom:1px solid #02aa8f}.notifications .warning{background-color:#F7CA18;border-bottom:1px solid #e7ba08}.notifications .close-click{font-size:12px;cursor:pointer;padding:10px;margin:0 auto}

.ng-click {
    cursor: pointer;
}

/* ===================== FILE INPUT ===================== */
.file-area {
    width: 100%;
    position: relative;
}

.file-area input[type=file] {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
}
.file-area .file-dummy .default {
    color: #ff3e25;
}

.file-area .file-dummy {
    width: 100%;
    padding: 30px;
    /*background: rgba(255, 255, 255, 0.2);*/
    background: #CCCCCC;
    border: 2px dashed rgba(255, 255, 255, 0.2);
    text-align: center;
    transition: background 0.3s ease-in-out;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.file-area .file-dummy .success {
    display: none;
}
.file-area:hover .file-dummy {
    background: #dddddd;
}
.file-area input[type=file]:focus + .file-dummy {
    outline: 2px solid rgba(255, 255, 255, 0.5);
    outline: -webkit-focus-ring-color auto 5px;
}
.file-area input[type=file]:valid + .file-dummy {
    border-color: rgba(0, 255, 0, 0.4);
    background-color: rgba(0, 255, 0, 0.3);
}
.file-area input[type=file]:valid + .file-dummy .success {
    display: inline-block;
}
.file-area input[type=file]:valid + .file-dummy .default {
    display: none;
}

/* Beyond theme edits */

.page-sidebar .sidebar-menu .submenu > li > a {
    padding-left: 16px;
    height: 100%;
}

.page-sidebar .sidebar-menu > li > .submenu::before {
    left: 10px;
}

.page-sidebar .sidebar-menu > li > .submenu > li > a::before {
    left: 8px;
}
/* /Beyond theme edits */

/* smart-table */
.st-sort-ascent:before{
    content: '\25B2';
}

.st-sort-descent:before{
    content: '\25BC';
}

.st-selected{
    background: #216eff !important;
    color: white !important;
}
/* /smart-table */

.wizard ul li.complete:before, .wizard ul li.active:before {
    z-index: 1;
}

.step-content > .actions {
    right: 16px;
    top: 47px;
}

#changeHeight {
    min-height: 400px;
}

.suggestion-step-2-2 {
    display: none;
}

.couple-provider {
    padding: 10px 0;
    margin: 0;
    margin-bottom: 10px;
    border-radius: 10px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
    transition: 0.5s linear all;
}

form.ng-dirty > .couple-provider {
    background-color: rgba(255,0,0,0.1);
}

form.ng-pristine button[type="submit"], form.ng-pristine input[type="submit"] {
    display: none;
}

form.ng-pristine.always-show-submit button[type="submit"], form.ng-pristine input[type="submit"] {
    display: inline-block;
}

form.hide-submit-on-tabs.selected-tab-1 button[type="submit"]:not(.always-show),
form.hide-submit-on-tabs.selected-tab-1 input[type="submit"]:not(.always-show),
form.hide-submit-on-tabs.selected-tab-2 button[type="submit"]:not(.always-show),
form.hide-submit-on-tabs.selected-tab-2 input[type="submit"]:not(.always-show) {
    display: none !important;
}

form.form-horizontal-condensed .form-group {
    margin-bottom: 0;
}

table.white-bordered > tbody > tr > td, table.white-bordered > thead > tr > th {
    border-color: #fff;
}

.navbar .navbar-inner .navbar-header .navbar-account .account-area .login-area {
    min-width: 150px;
}

.hidden-select {
    height: 1px;
    width: 1px;
    border: 0;
    padding: 0;
    float: left;
}

strong > .ui-select-highlight {
    text-decoration: underline;
}

th[st-sort]:not(.st-sort-ascent):not(.st-sort-descent):before {
    font-family: FontAwesome;
    content: '\f0dc  ';
}

td.has-number, th.has-number {
    text-align: right;
}

.page-selector {
    width: 75px !important;
}

.full-width {
    width: 100%!important;
}

.full-width.form-control {
    width: 100%;
}

.star-top-left{
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 9;
}

.title.type-1{
    padding: 20px 0;
}

@media (min-width: 768px) {
    .ui-select-container .ui-select-search {
        width: inherit !important;
    }
}


/* smart-table */
.dg-table-sort-ascent:before {
    content: '\25B2';
}

.dg-table-sort-descent:before {
    content: '\25BC';
}

.dg-table-selected {
    background: #216eff !important;
    color: white !important;
}

th[dg-table-sort]:not(.dg-table-sort-ascent):not(.dg-table-sort-descent)::before {
    font-family: FontAwesome;
    content: '\f0dc  ';
}




.full-width {
    width: 100%;
}

.gallery-image {
    position: relative;
    min-height: 100px;
}

.gallery-image .top-left {
    position: absolute;
    top: 8px;
    left: 8px;
}

.gallery-image .top-right {
    position: absolute;
    top: 5%;
    right: 5%;
}

.gallery-image .middle-center {
    position: absolute;
    top: 30%;
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.gallery-image .gallery-image-buttons {
    position: absolute;
    bottom: 80px;
    right: 5px;
    left: 5px;
    background-color: rgba(255,255,255, 0.75);
}

.gallery-image .gallery-image-buttons .checkbox {
    margin: 3px 0;
    padding: 0 3px;
}

.gallery-image .gallery-image-buttons .checkbox .text {
    font-weight: bold;
}

.provider-stats .databox .databox-left{
    padding:5px;
    font-size:16px;
    width:90px
}

.provider-stats .databox .databox-right{
    width:-webkit-calc(100% - 90px);
    width:-moz-calc(100% - 90px);
    width:calc(100% - 90px)
}

.provider-stats .databox .databox-left p:only-child{
    line-height:58px
}

.lh-20 {
    line-height: 20px!important;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    color: #444;
}
.ui-select-bootstrap > .ui-select-match > .btn, .ui-select-bootstrap .ui-select-choices-row>a {
    white-space: normal;
}

.label-black, .badge-black {
    background-color: black;
    color: white;
    background-image: none !important;
}
.increase-popover-width {
    max-width: 500px;
}

.single-select .ui-select-container.ng-not-empty span.form-control.ui-select-toggle, .ui-select-match {
    height: auto;
}
.medium-editor-element {
    padding: 5px 10px;
    border: 1px solid #d5d5d5;
    overflow-y: auto;
    height: 200px;
}

.intl-tel-input {
    width: 100%;
}

.intl-tel-input .flag-dropdown {
    z-index: 3;
}

.iti-flag {
    background: url("../images/flags.png");
}

.login-container {
    margin: 5% auto;
    max-width: 400px;
}
.login-container .loginbox {
    width: 400px !important;
}

.login-logo{
    display: block;
    margin-bottom:30px;
    width: 400px;
    height: 60px;
    background-repeat: no-repeat;
    background-color:#2dc3e8;
    background-position: center center;
}

.navbar .navbar-inner .navbar-header .navbar-account .account-area .login-area section {
    line-height: inherit!important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    max-width: 300px;
}

.country-phone::placeholder {
    color: #d0cbd7;
    font-style: italic;
}

.angular-google-map-container { height: 400px; }

/*
Star rating styles
 */
.rating {
    color: #a9a9a9;
    margin: 0;
    padding: 0;
}
ul.rating {
    display: inline-block;
}
.rating li {
    list-style-type: none;
    display: inline-block;
    padding: 1px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
}
.rating .filled {
    color: red;
}
.no-data {
    background: #fff0c5 !important;
}
.bold-text{
    font-weight: 700;
}
.gallery-image .image-container img {
    min-height: 100px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}